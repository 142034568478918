/**
 * Do not edit this file! 
 * You should not write CSS directly when using React Native.
 * We are using CSS resets here to support React Native for Web and Tailwind CSS.
 */

@tailwind base;
@tailwind components;
@tailwind utilities;

/**
 * Building on the RNWeb reset:
 * https://github.com/necolas/react-native-web/blob/master/packages/react-native-web/src/exports/StyleSheet/initialRules.js
 */
html,
body,
#__next {
    width: 100%;
    /* To smooth any scrolling behavior */
    -webkit-overflow-scrolling: touch;
    margin: 0px;
    padding: 0px;
    /* Allows content to fill the viewport and go beyond the bottom */
    min-height: 100%;
}

#__next {
    flex-shrink: 0;
    flex-basis: auto;
    flex-direction: column;
    flex-grow: 1;
    display: flex;
    flex: 1;
}

html {
    /* Prevent text size change on orientation change https://gist.github.com/tfausak/2222823#file-ios-8-web-app-html-L138 */
    -webkit-text-size-adjust: 100%;
    height: 100%;
}

body {
    display: flex;
    /* Allows you to scroll below the viewport; default value is visible */
    overflow-y: auto;
    overscroll-behavior-y: none;
    text-rendering: optimizeLegibility;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    -ms-overflow-style: scrollbar;
}

.calendly-spinner {
    display: none !important;
}

html {
    scroll-behavior: smooth;
}

.always-visible-section-outline {
    fill: #dff4ff;
    stroke: #a3aaaf;
    stroke-width: 10px;
  }
  .isOpen .always-visible-section-outline {
    fill: rgba(243, 243, 243, 0.8);
    stroke: #a3aaaf;
    stroke-dasharray: 30px 20px;
    stroke-width: 10px;
  }
  .section-text {
    fill: #202020;
    font-size: 250px;
    font-weight: bold;
  }
  .section-text.large {
    font-size: 500px;
  }
  .row-number-text {
    fill: #202020;
    font-size: 100px;
  }
  .svg-fill {
    fill: rgb(163, 179, 198);
  }
  .st4 {
    fill: rgb(10, 34, 64);
    stroke: rgb(255, 255, 255);
    stroke-width: 11.3772px;
  }
  
  .svg-hover:hover {
    fill: rgb(10, 34, 64);
    stroke: rgb(255, 255, 255);
    stroke-width: 11.3772px;
  }

  .svg-blocked {
    fill: rgb(150, 150, 150);
  }

  .svg-pool {
    fill: rgba(163, 179, 198, 0.8) !important;
  }
  
  .svg-hover-blocked:hover {
    fill: rgb(10, 34, 64);
    stroke: rgb(255, 255, 255);
    stroke-width: 11.3772px;
  }
  